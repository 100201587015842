import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Formik } from "formik";
import { addProperty, getProperty, getUsers, updateProperty } from "../redux/actions/adminActions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import AutoCompleteSearch from "../Components/Layout/AutoCompleteSearch";
import { FiCloudLightning } from "react-icons/fi";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function AddProperty() {
  const BASE_URL = process.env.REACT_APP_HOST_NAME;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let query = useQuery();
  let queryId = query.get("id");
  const [image, setImage] = useState();
  const [prevImg, setPrevImg] = useState([]);
  const [googleLat, setGoogleLat] = useState();
  const [googleLong, setGoogleLong] = useState();
  const [city, setCity] = useState();
  const [address, setAddress] = useState();
  const [postcode, setPostcode] = useState();
  const [userDetail, setUserDetail] = useState();
  const { userDetails, users } = useSelector((state) => ({
    userDetails: state.managePropertySlice.userDetails,
    users: state.userMgmtSlice.users
  }));

  const handleImgUpload = (e, setFieldValue) => {
    const newImageUrls = [];
    [...e.target.files].forEach((image) => newImageUrls.push(URL.createObjectURL(image)));

    console.log(newImageUrls, "imageURLS");

    setPrevImg(newImageUrls);
    setImage([...e.target.files]);
    setFieldValue('property_image', [...e.target.files])
  };

  useEffect(() => {
    if (queryId) {
      dispatch(getProperty(queryId)).then((data) => {
        setUserDetail(data?.payload?.data);
      });
    }

  }, [queryId])


  useEffect(() => {
    dispatch(getUsers({ page: 1, limit: 1000000, search: "", user_type: 2 }));
  }, []);




  console.log(city,address,postcode, "query");
  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <h2>Add New Property</h2>

          <div>
            <hr />
          </div>
        </div>
      </div>
      <Container fluid>
        <div className="product-cmn-tab">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/admin/ManageProperties">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      <Container fluid>
        <Row className="justify-content-center">
          <Col lg={8} className="mb-4">
            <div className="customer-form-new">
              <h2>BASIC DETAILS</h2>
              <hr className="cmn-border" />
              <div className="customer-form-inner">

                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    property_name: userDetail?.property_name || '',
                    property_address: userDetail?.property_address || '',
                    property_state: userDetail?.property_state || '',
                    property_city: userDetail?.property_city || '',
                    property_country: userDetail?.property_country || '',
                    property_postal_code: userDetail?.property_postal_code || '',
                    property_lat: userDetail?.property_lat || '',
                    property_long: userDetail?.property_long || '',
                    assign_property_manager: userDetail?.assign_property_manager || '',
                    property_image: userDetail?.property_image || []
                  }}
                  validate={(values) => {
                    console.log(values)
                    const errors = {};
                    if (!values.property_name) {
                      errors.property_name = "Required*";
                    }
                    if (!values.property_address) {
                      errors.property_address = "Required*";
                    }
                    // if (!values.property_state) {
                    //   errors.property_state = "Required*";
                    // }
                    if (!values.property_city) {
                      errors.property_city = "Required*";
                    }
                    // if (!values.property_country) {
                    //   errors.property_country = "Required*";
                    // }
                    if (!values.property_postal_code) {
                      errors.property_postal_code = "Required*";
                    }
                    if (!values.assign_property_manager) {
                      errors.assign_property_manager = "Required*";
                    }

                    console.log(errors,"err")

                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    console.log(values, "values");

                    const formData = new FormData();
                    for (let key in values) {
                      if (key == 'property_image') {
                        values[key].forEach((file) => {
                          formData.append(`property_image`, file);
                        })
                      } else {
                        formData.append(key, values[key]);
                      }
                    }

                    if (userDetail) {
                      formData.append(`id`, userDetail?._id);
                      dispatch(updateProperty(formData)).then((data) => {
                        if (data?.payload?.success) {
                          //toast.success(data?.payload?.message)
                          navigate(`/admin/ManageProperties`)
                        }
                      });
                    } else {

                      dispatch(addProperty(formData)).then((data) => {
                        if (data?.payload?.success) {
                          toast.success(data?.payload?.message)
                          navigate(`/admin/ManageProperties`)
                        }
                      });
                    }


                    setSubmitting(false);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <Row>
                        <Col lg={12}>
                          <Form.Group className="mb-3 image-upload-main">
                            <Form.Label>Property Image</Form.Label>
                            <div className="input-image-show">
                              <Form.Control
                                type="file"
                                multiple
                                placeholder="Upload Image"
                                onChange={(e) => handleImgUpload(e, setFieldValue)}
                              />


                              {prevImg?.length > 0 ? prevImg?.map((item) => {
                                return (
                                  <img src={item} />
                                )
                              }) : userDetail?.property_image?.length > 0 ? userDetail?.property_image?.map((item) => {
                                return (
                                  <img src={BASE_URL + item} />
                                )
                              }) : <p>Upload Image</p>}
                            </div>
                          </Form.Group>
                        </Col>
                        <Col lg={12}>
                          <Form.Group className="mb-3">
                            <Form.Label>Property name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Type Here"
                              name="property_name"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.property_name}
                            />
                            <span className="formik-errors">
                              {errors.property_name && touched.property_name && errors.property_name}
                            </span>
                          </Form.Group>
                        </Col>

                        <Col lg={12}>
                          <Form.Group className="mb-3">
                            <Form.Label>Google Address Search</Form.Label>
                            <AutoCompleteSearch
                            setFieldValue={setFieldValue}
                              // setGoogleLat={setGoogleLat}
                              // setGoogleLong={setGoogleLong}
                              // setCity={setCity}
                              // setPostcode={setPostcode}
                              // setAddress={setAddress}
                            />
                          </Form.Group>
                        </Col>
                        <Col lg={12}>
                          <Form.Group className="mb-3">
                            <Form.Label>Address</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Type Here"
                              name="property_address"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.property_address}
                            />
                            <span className="formik-errors">
                              {errors.property_address && touched.property_address && errors.property_address}
                            </span>
                          </Form.Group>
                        </Col>
                        <Col lg={12}>
                          <Form.Group className="mb-3">
                            <Form.Label>City</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Type Here"
                              name="property_city"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.property_city}
                            />
                            <span className="formik-errors">
                              {errors.property_city && touched.property_city && errors.property_city}
                            </span>
                          </Form.Group>
                        </Col>
                        <Col lg={12}>
                          <Form.Group className="mb-3">
                            <Form.Label>Postal Code</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Type Here"
                              name="property_postal_code"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.property_postal_code}
                            />
                          </Form.Group>
                          <span className="formik-errors">
                            {errors.property_postal_code && touched.property_postal_code && errors.property_postal_code}
                          </span>
                        </Col>
                        <Col lg={12}>
                          <div className="admin-box-select">
                            <Form.Group className="mb-3">
                              <Form.Label>Assign Property Manager</Form.Label>

                              <Form.Select
                                aria-label="Default select example"
                                name="assign_property_manager"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.assign_property_manager}
                              >
                                <option value=''>Select Property Manager</option>
                                {
                                  users?.map((item) => {
                                    return (
                                      <option value={item?._id}>{item?.first_name + " " + item?.last_name}</option>
                                    )
                                  })
                                }

                              </Form.Select>
                           
                              {/* <span>Multiple Selection</span> */}
                            </Form.Group>
                            <span className="formik-errors">
                                {errors.assign_property_manager && touched.assign_property_manager && errors.assign_property_manager}
                              </span>

                          </div>
                        </Col>
                        <Col lg={12} className="d-flex justify-content-center">
                          <button type="submit" className="add-btn">
                            Add
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
